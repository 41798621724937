import React from 'react';
import {Control, Controller, FieldValue} from 'react-hook-form';
import {Selector} from '../../fields';
import {selectorSize} from '../../fields/Selector';

interface IFormSelectorProps {
    control: Control<FieldValue<any>>;
    options: string[];
    error: any;
    name: string;
    label: string;
    size: selectorSize.lg | selectorSize.xl;
}

export const FormSelector = (
    {control, options, error, name, label, size}: IFormSelectorProps
) => {
    return (
        <div className="wrapper-custom-select" >
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, onBlur, value}}) =>
                 <Selector
                        label={label}
                        items={options}
                        error={error}
                        defaultValue={value}
                        handler={onChange}
                        size={size}
                    />
                }
            />
        </div>
    );
};
