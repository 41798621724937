import React, {useEffect} from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {MainLayout} from '../../layouts';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {InputSearch} from '../../components';
import {setProjectNameAnalytics} from '../analytics/store/analytics.slice';
import {ProjectItem} from './components';
import {clearContentList, getByProjectId, getContentByFilter, getContentByProjectId} from './store/project.slice';

export const ProjectPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id = ''} = useParams();
    const {loader, contentList, nextCursor} = useAppSelector(state => state.project);

    useEffect(() => {
        if(id) {
            dispatch(getByProjectId({ id, navigate}));
        }
    }, []);

    const getByFilter = (value: string) => {
        dispatch(getContentByFilter({id: id, value, nextCursor}));
    };

    const handleOnBlur = (value: string) => {
        if(value.length === 0) {
            dispatch(clearContentList());
            dispatch(getContentByProjectId({id, navigate,}));
        }
    };

    const handleMoreContent = () => {
        dispatch(getContentByProjectId({id, navigate,}));
    };

    const handleBack = () => {
        dispatch(clearContentList());
        dispatch(setProjectNameAnalytics(''));
        navigate('/');
    };

    return (
        <MainLayout
            loader={loader}
            header={<Header/>}
            content={
                <div className="playerHistory">
                    <div className="playerHistory_center">
                        <p className="playerHistory-text">
                            Работает с любым сайтом
                        </p>
                        <h2 className="playerHistory-title">
                            Голосовая озвучка сайта
                        </h2>

                        <div className="playerHistory_row">
                            <div className="playerHistory_item_full">
                                <p className="playerHistory_item-sectionTitle">
                                    <a className="breadcumb_url" href={'/'}>Мои проекты</a> ➔
                                    <span className="breadcumb_current"> Контент</span>
                                </p>
                                <div className={'playerHistory-toolbar'}>
                                    <div className={'playerHistory-toolbar__buttons multibuttons'}>
                                        <NavLink to={`/content/${id}/update`}
                                                 type={'button'}
                                                 className="playerCreater-generate"
                                        >Создать озвучку</NavLink>
                                        <NavLink to={`/analytics/${id}`}
                                                 type={'button'}
                                                 className="playerCreater-generate"
                                        >Аналитика</NavLink>

                                        <a href={'/'}
                                            type={'button'}
                                            className="playerCreater-generate"
                                            onClick={handleBack}
                                        >Назад
                                        </a>
                                    </div>
                                    <InputSearch
                                        placeholder={'Введите ссылку для поиска озвучки'}
                                        handler={value => getByFilter(value)}
                                        onBlur={handleOnBlur}
                                        data={contentList}/>
                                </div>
                                    <InfiniteScroll
                                        className={'playerHistory_item_full__inner'}
                                        dataLength={contentList?.length}
                                        next={handleMoreContent}
                                        hasMore={nextCursor?.length > 0}
                                        loader={<h4>Loading...</h4>}
                                        height={450}
                                        endMessage={
                                            <p
                                                className={'playerHistory_block-section'}
                                                style={{textAlign: 'center', margin: '20px 0'}}>
                                                Это все ваши озвучки
                                            </p>
                                        }  >
                                        {contentList?.map(i => (
                                            <ProjectItem
                                                key={i.id}
                                                project_id={i.project_id}
                                                id={i.id}
                                                source={i.source}
                                                status={i.status}
                                                title={i.title}
                                                symbols={i.symbols}
                                                created_at={i.created_at.split('T')[0]}
                                            />
                                        ))}
                                    </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            }
            footer={<Footer/>}/>
    );
};
