import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import settingsIcon from '../../../assets/images/icon/settings.svg';
import {IProjectListItem} from '../../projects/store/types';
import detailIcon from '../../../assets/images/icon/p2.svg';
import removeIcon from '../../../assets/images/icon/remove.svg';
import {useAppDispatch} from '../../../hooks';
import {getProjectById, projectDelete} from '../../projects/store/project.slice';
import {Modal} from '../../../components';
import {setProjectNameAnalytics} from '../../analytics/store/analytics.slice';

export const ProjectItem = ({id, name, created_at}:IProjectListItem) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState<boolean>(false);

    const handleJumpEditProject = () => {
        dispatch(getProjectById({id, navigate}));
    };

    const handleGetContent = () => {
        navigate(`/content/${id}`);
        dispatch(setProjectNameAnalytics(name));
        //dispatch(getContentByProjectId({ id, navigate}));
    };

    const handleDelete = () => {
        dispatch(projectDelete({id}));
    };

    return (
        <div>
            <div className="playerHistory_box">

                <div className="playerHistory_block">
                    <p className="playerHistory_block-section">Создан</p>
                    <span className="playerHistory_block-in">{created_at}</span>
                </div>
                <div className="playerHistory_block">
                    <p className="playerHistory_block-section">Имя проекта</p>
                    <span className="playerHistory_block-in">{name}</span>
                </div>
                <div className="playerHistory_block">
                <span className="playerHistory_block-button">
                    Копировать JS
                </span>
                </div>
                <div className="playerHistory_block playerHistory_block--button">
                    <p className="playerHistory_block-section">Настройки</p>
                    <span onClick={handleJumpEditProject} className="playerHistory_block-remove">
                    <img src={settingsIcon} alt={'icon'}/>
                </span>
                </div>
                <div className="playerHistory_block playerHistory_block--button">
                    <p className="playerHistory_block-section">Озвучки</p>
                    <span onClick={handleGetContent} className="playerHistory_block-remove">
                    <img src={detailIcon} alt={'icon'}/>
                </span>
                </div>
                <div className="playerHistory_block playerHistory_block--button">
                    <p className="playerHistory_block-section">Удалить проект</p>
                    <span className="playerHistory_block-remove" onClick={() => setOpen(true)}>
                    <img className="remove_unactiv" src={removeIcon} alt={'icon'}/>
                </span>
                </div>
            </div>
            <div>
                <Modal isVisible={isOpen} handleClose={() => setOpen(!isOpen)}>
                    <div className={'confirm-delete'}>
                        <p className={'confirm-delete__text'}>Точно требуется удалить проект?</p>
                        <div className={'confirm-delete__buttons'}>
                            <button
                                type={'button'}
                                className="playerCreater-generate"
                                onClick={handleDelete}
                            >Да
                            </button>
                            <button
                                type={'button'}
                                className="playerCreater-generate"
                                onClick={() => setOpen(false)}
                            >Нет
                            </button>

                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};
