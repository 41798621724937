import React from 'react';
import {Control, Controller, FieldValue, UseFormRegister,} from 'react-hook-form';
import {CheckedIcon} from '../../assets/customIcons';
import {lineEndingCheck, lineEndingCheckOnlyHttps} from '../../helpers/lineEndingCheck';
import {typeValidationEnum} from '../../store/types';

interface IFormInputProps {
    control: Control<FieldValue<any>>;
    register: UseFormRegister<any>;
    error: any;
    watch: string;
    name: string;
    showChecked: boolean;
    typeValidation: typeValidationEnum.url | typeValidationEnum.onlyHttps
}

export const FormInput = (
    {
        register,
        error,
        watch,
        name,
        showChecked,
        control,
        typeValidation
    }: IFormInputProps
) => {

    const handleValidationType = (typeValidation) => {
        if (typeValidation === typeValidationEnum.url) {
            return lineEndingCheck(watch);
        } else {
            return lineEndingCheckOnlyHttps(watch);
        }

    };

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, onBlur, value}}) =>
                <div className={'input-wrapper'}>
                    <input
                        {...register(name)}
                        type="text"
                        name={name}
                        className={error && 'error-input'}
                    />
                    {showChecked
                        ? (<div className={'input-wrapper-checked'}>
                            <CheckedIcon
                                color={handleValidationType(typeValidation) ? '#00E011FF' : '#565656'}/>
                        </div>)
                        : null}
                </div>
            }
        />

    );
};
