import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {MainLayout} from '../../layouts';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {Modal} from '../../components';
import {selectorSize} from '../../fields/Selector';
import {FormSelector} from '../../components/formComponents';
import {alertTypes} from '../../store/app.slice';
import {AudioPlayer} from '../../components/audioPlayer';
import {
    clearItemContent,
    getContentById,
    initContent,
    updateContent
} from './store/project.slice';
import {DescriptionItem} from './components';
import {ICreateContentData} from './store/types';

const statusTypes = [
    {color: '#ffa500', name: 'В процессе'},
    {color: '#00E011FF', name: 'Озвучен'},
    {color: '#d62555', name: 'Ошибка '}
];

const schema = yup.object({
    title: yup.string(),
    voice: yup.string().required('Выберите голос для озвучки'),
    content: yup.string().min(100, 'Минимум 100 символов').required(),
    url: yup.string().required('Это поле обязательно'),
    lang: yup.string()
});

export const ProjectContent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id = ''} = useParams();
    const {
        project_id,
        voice,
        title,
        content,
        symbols,
        mp3,
        source,
        url,
        status,
        created_at,
    } = useAppSelector(state => state.project.content);
    const {loader, data} = useAppSelector(state => state.project);
    const {voices, languages, alerts} = useAppSelector(state => state.app);

    const {
        type_player
    } = data.player;

    const voiceItems = voices.map(i => i.voice);
    const languageItems = languages.map(i => i.language);

    const [isVisible, setVisible] = useState<boolean>(false);
    const [isEdit, setEdit] = useState<boolean>(false);

    const {setValue, control, register, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            title: title,
            voice: voice,
            lang: languageItems[0],
            content: content,
            url: url,
        },
        mode: 'all',
        shouldUnregister: false,
    });

    useEffect(() => {
        setValue('title', title);
        setValue('voice', voices.filter(i => i.voice_name === voice).map(i => i.voice)[0]);
        setValue('content', content);
        setValue('url', url);
        setValue('voice', voice);
    }, [title, voice, content, url]);

    useEffect(() => {
        if (alerts.type === alertTypes.SUCCESS) {
            setEdit(false);
        }
    }, [alerts]);

    useEffect(() => {
        dispatch(getContentById({id}));
        return () => clearComponentData();
    }, []);

    function clearComponentData() {
        dispatch(clearItemContent(initContent));
    }

    const submitUpdate = handleSubmit(values => {
        const data: ICreateContentData = {
            url: values.url,
            content: values.content,
            voice: voices.filter(i => i.voice === values.voice).map(i => i.voice_name)[0],
            title: values.title,
        };
        dispatch(updateContent({data, navigate, id}));
    });

    const date = new Date(created_at);

    return (
        <MainLayout
            loader={loader}
            header={<Header/>}
            content={
                <div className="playerCode content-page">
                    <div className="playerCode_center">
                        <p className="playerCode-text">
                            Работает с любым сайтом
                        </p>
                    <h2 className="playerCode-title">
                        Голосовая озвучка сайта
                    </h2>
                        <div className="playerCode_row">
                            <div className="playerCode_item">
                                <p className="playerCode_item-sectionTitle">
                                    <a className="breadcumb_url" href={'/'}>Мои проекты</a> ➔ <a
                                    className="breadcumb_url" href={`/content/${project_id}`}>Контент</a> ➔
                                    <span className="breadcumb_current"> Детальная информация</span>
                                </p>

                                <div className="playerCode_item-textarea">
                                    <NavLink to={`/analytics-content/${id}`}
                                        className="playerHistory_block-button"
                                        >
                                        Статистика прослушиваний
                                    </NavLink>
                                    <DescriptionItem label={'ID озвучки: '} color={''} text={id} />
                                    <DescriptionItem label={'Дата создания: '} color={''} text={date.toLocaleString()}/>
                                    <DescriptionItem label={'Заголовок: '} color={''} text={title}/>
                                    <DescriptionItem label={'Количество символов: '} color={''} text={symbols}/>
                                    <DescriptionItem label={'Источник: '} color={''} text={source}/>
                                    <DescriptionItem label={'Ссылка: '} color={''} text={url}/>
                                    <DescriptionItem label={'Статус: '} color={statusTypes[status]?.color} text={statusTypes[status]?.name}/>

                                    <div className="display_item_voice_result_box">

                                        {mp3 && <AudioPlayer
                                            file={`https://api.apihost.ru/v1/player/remote?file=${mp3}`}
                                            type_player={type_player}
                                            background_color={'#293039'}
                                            main_color={'#FFFFFF'}
                                            border_color={'#293039'}
                                            border_radius={'0'}
                                        />}
                                    </div>
                                    <div className="display_item_voice_parametrs">
                                        <div className="display_item_voice_parametrs_textarea">
                                            {isEdit
                                                ? (
                                                    <div className="display_item_voice_parametrs_selects select-group">
                                                        <FormSelector
                                                            control={control}
                                                            options={languageItems}
                                                            error={errors.lang}
                                                            name={'lang'}
                                                            label={'Русский'}
                                                            size={selectorSize.lg}
                                                        />
                                                        <FormSelector
                                                            control={control}
                                                            options={voiceItems}
                                                            error={errors.voice}
                                                            name={'voice'}
                                                            label={'Голос'}
                                                            size={selectorSize.lg}
                                                        />
                                                    </div>
                                                ) : null}


                                            <div className="display_item_voice_parametrs_textarea">
                                                <Controller
                                                    name={'content'}
                                                    control={control}
                                                    render={({field}) => (
                                                        <textarea
                                                            // placeholder={'Текст импортированной статьи из RSS ленты'}
                                                            {...register('content')}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="display_item_voice_parametrs_textarea-box">
                                                <div className="display_item_voice_parametrs_textarea-block"
                                                     onClick={() => setEdit(!isEdit)}>
                                                    <p>
                                                        <span className="change">{isEdit ? 'отмена' : 'изменить'}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="display_item_voice_parametrs_controler">
                                        {isEdit ? (<div className="display_item_voice_parametrs_controler-box">
                                            <button id="next"
                                                    style={{color: 'rgb(145, 148, 151)', border: 'none!important'}}
                                                    onClick={submitUpdate}>
                                                <img id="iconplay" src="https://apihost.ru/images/icon/play_1.svg"
                                                     alt={'icon'}
                                                     style={{filter: 'contrast(0.1)'}}/>
                                                Переозвучить
                                            </button>
                                        </div>) : null}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <span className="playerCreater-generate" onClick={() => navigate(-1)}>Назад</span>
                    </div>
                    <Modal isVisible={isVisible} handleClose={() => setVisible(!isVisible)}>
                        <div className={'modal-info'}>
                            <p className={'modal-info__text'}>Функционал находится в стадии разработки.</p>
                        </div>
                    </Modal>
                </div>
            }
            footer={<Footer/>}
        />
    );
};
