import React from 'react';

export const DescriptionItem = ({label, text, color}:{label:string, text:string, color:string}) => {
    return (
        <p className="contentitem-detail">
            {label}
            <span className="contentitem-value" style={{color: color}}>{text}</span>
        </p>
    );
};
