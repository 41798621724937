import React, {useState} from 'react';
import { Calendar } from 'react-date-range';
import {ru} from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './style.css';
import {formatDateString} from '../../helpers/formatDateString';

interface IDate {
    startDate: Date;
    endDate: Date;
    key: string
}

interface IDateRange {
    range1 : IDate
}

const defaultDay: string = formatDateString(new Date());

interface ICustomDateRangePickerProps {
    handleChangeDate: (date:string) => void;
}

export const CustomDatePicker = ({handleChangeDate}:ICustomDateRangePickerProps) => {
    const [show, setShow] = useState<boolean>(false);
    const [dates, setDates] = useState<Date>(new Date());
    const [outputDates, setOutputDates] = useState<string>(defaultDay);
    const onSelect = (date: Date) => {
        const str = formatDateString(date);
        setDates(date);
        handleChangeDate(str);

        setOutputDates(str);
        setShow(false);
    };

    return (
        <div className={'picker'}>
            <div className={'picker__output-date'} onClick={() => setShow(!show)}>{outputDates}</div>
            {show ?(<>
                <div className={'picker__overlay'} onClick={() => setShow(false)}/>
                <Calendar
                    date={dates}
                    onChange={onSelect}
                    editableDateInputs={false}
                    locale={ru}
                    showMonthAndYearPickers={false}
                />
            </>): null}
        </div>
    );
};
