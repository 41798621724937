import React from 'react';
import {IContentAnalytics} from '../store/types';

interface IItemProps extends IContentAnalytics {
    projectName: string;
    date: string;
}

function calc(click, view : number): number {

    const calc = 100*view/click;
    return isNaN(calc) ? 0 : Math.trunc(calc);
}

export const Item = ({projectName, date, click, view, heard}:IItemProps) => {

    return (
        <div className="playerHistory_box content_box">
            <div className="playerHistory_block">
                <p className="playerHistory_block-section">Проект</p>
                <p className="playerHistory_block-in">{projectName}</p>
            </div>
            <div className="playerHistory_block">
                <p className="playerHistory_block-section">Дата</p>
                <p className="playerHistory_block-in">{date}</p>
            </div>
            <div className="playerHistory_block">
                <p className="playerHistory_block-section">Показов</p>
                <p className="playerHistory_block-in">{view}</p>
            </div>
            <div className="playerHistory_block">
                <p className="playerHistory_block-section">Кликов</p>
                <p className="playerHistory_block-in" >{click}</p>
            </div>
            <div className="playerHistory_block">
                <p className="playerHistory_block-section">Дослушано</p>
                <p className="playerHistory_block-in" >{heard}</p>
            </div>
            <div className="playerHistory_block">
                <p className="playerHistory_block-section">Эффективность</p>
                <p className="playerHistory_block-in" >{calc(view, click)}%</p>
            </div>
        </div>
    );
};
