import {combineReducers, configureStore, Reducer} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import logger from 'redux-logger';
import auth from '../pages/auth/store/auth.slice';
import project from '../pages/projects/store/project.slice';
import analytics from '../pages/analytics/store/analytics.slice';
import app from './app.slice';

const rootReducer = combineReducers({
    app,
    auth,
    project,
    analytics
});

const appReducer: Reducer = (state, action) => {
    if (action.type === 'app/clearStorage') {
        storage.removeItem('persist:root');
        state = {} as RootState;
    }
    return rootReducer(state, action);
};

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(logger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
