import React, {useState} from 'react';
import barIcon from '../../assets/images/icon/bar.svg';
import {Avatar, Navigation} from './components';


export const Header = () => {
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    return (
        <>
            <header className="header">
                <div className="center">
                    <div className="header_row">
                        <div className="header_item">
                            <a href="https://audio.apihost.ru">
                                <img
                                    src="https://apihost.ru/images/logo-header.png"
                                    alt="APIHOST.RU"
                                    srcSet="https://apihost.ru/images/logo-header@2x.png 2x, https://apihost.ru/images/logo-header@3x.png 3x"
                                    className="header_logo"
                                />
                            </a>
                            <img src={barIcon} alt="icon" className="bar" onClick={() => setOpenMenu(true)}/>
                        </div>
                        <div className={openMenu ? 'header_item navbar navbar_active' : 'header_item navbar'}>

                            <Navigation/>
                            {/*<MobilePageMenu handleClose={() => setOpenMenu(false)} />*/}
                            <img
                                src="https://apihost.ru/images/icon/close.svg"
                                alt="icon"
                                className="bar_close"
                                onClick={() => setOpenMenu(false)}
                            />
                            <span className="bar_website">“APIHOST.RU”</span>
                        </div>

                        <div className="header_item authorize">
                            {/*<Balance />*/}
                            <Avatar/>
                        </div>
                    </div>
                </div>
            </header>
            <div className="information">
                <div className="center">
                    <p>
                        Работаем только на качество, выполняем постоянные обновления и улучшения функционала всего
                        списка программ. По всем вопросам <a href="mailto:support@apihost.ru">пишите нам</a>
                    </p>
                </div>
            </div>
        </>
    );
};
