import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {ErrorMessage} from '@hookform/error-message';
import {Modal} from '../../../components';
import address from '../../../assets/images/icon/addres.svg';
import {lineLength} from '../../../helpers/lineEndingCheck';
import {CheckedIcon} from '../../../assets/customIcons';
import {createProject} from '../../projects/store/project.slice';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {ProjectItem} from './ProjectItem';

const schema = yup
    .object({
        name:  yup.string().required()
    });

export const Projects = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {projectList} = useAppSelector(state => state.project);

    const [isVisible, setVisible] = useState<boolean>(false);

    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        resolver: yupResolver(schema)
    });
    const watchName = watch('name');

    const submit = handleSubmit((data) => {
        const {name} = data;
        dispatch(createProject({name, navigate}));
        setVisible(false);
    });

    return (
        <div className="playerHistory_item">
            <p className="playerHistory_item-sectionTitle">
                {projectList?.length > 0 ? 'Мои проекты' : 'Создайте свой первый проект'}
            </p>
            <span className="playerCreater-generate" onClick={() => setVisible(true)}>Новый проект</span>
            {projectList?.map(item => (
                <ProjectItem key={item.id} id={item.id} name={item.name} created_at={item.created_at.split('T')[0]}/>
            ))}
            <div>
            <Modal isVisible={isVisible} handleClose={() => setVisible(!isVisible)}>
                <>
                    <p className="modal_pay_row-title">
                        <span className="playerCreater-title">Создать новый проект</span>
                        Каждый проект содержит свои настройки<br/>
                        Голос, RSS лента, аналитика итд.
                    </p>
                    <div className="playerCreater_item-form-box">
                         <span>
                              <img src={address} alt={'icon'}/>
                              Введите имя проекта
                          </span>
                        <form>
                            <label>
                                <ErrorMessage
                                    errors={errors}
                                    name="name"
                                    render={({message}) =>
                                        <p style={{color: 'red', fontSize: '12px'}}>{message}</p>
                                    }/>
                                <input
                                    {...register('name')}
                                    type="text"
                                    name="name"
                                    className={errors?.name && 'error-input'}
                                    placeholder="Проект новостей #1"/>
                            </label>
                            <button
                                className={'modal_pay_row-button'}
                                disabled={!lineLength(watchName)}
                                onClick={submit}>Создать
                            </button>
                        </form>
                        <div className={'playerHistory-modal-checked'}>
                            <CheckedIcon color={lineLength(watchName) ? '#00E011FF' : '#565656'}/>
                        </div>
                    </div>
                </>
            </Modal>
         </div>
        </div>

    );
};
