import React from 'react';

export const MessageAlert = ({style, options, message, close}: any) => {
    let bg: string;

    switch (options.type) {
        case 'info' :
            bg = '#006fff';
            break;
        case 'success':
            bg = '#00d200';
            break;
        default:
            bg = '#FF0000FF';
    }
    return (
        <div className={'message-alert'} style={{...style, backgroundColor: bg}}>
            {message}
            <button className={'message-alert__button'} onClick={close}/>
        </div>
    );
};
