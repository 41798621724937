import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {handleError} from '../../../helpers/handleError';
import api from '../../../api';
import {IAnalyticsState, IContentAnalytics} from './types';

const defaultData:IContentAnalytics = {
    click: 0,
    heard: 0,
    view: 0
};

const initialState:IAnalyticsState = {
    loading: false,
    data : defaultData,
    dataContent: defaultData,
    projectName: ''
};

interface IQueryProps {
    id: string,
    date: string
}

export const getProjectAnalytics =  createAsyncThunk(
    'analytics/getProjectAnalytics',
    async ({id, date}:IQueryProps, {dispatch}) => {
        try {
            const res = await api.get(`/analytic/project/${id}?q=${date}`);
            return res.status === 201 ? res.data.data :  defaultData;
        } catch (e) {
            handleError(e, dispatch);
        }
    }
);

export const getContentAnalytics = createAsyncThunk(
    'analytics/hetContentAnalytics',
    async ({id, date}:IQueryProps, {dispatch}) => {
        try {
            const res = await api.get(`/analytic/content/${id}?q=${date}`);
            return res.status === 201 ? res.data.data :  defaultData;
        } catch (e) {
            handleError(e, dispatch);
        }
    }
);

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setProjectNameAnalytics: (state, {payload}) => {
            state.projectName = payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(getProjectAnalytics.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProjectAnalytics.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.data = payload;
        });
        builder.addCase(getProjectAnalytics.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getContentAnalytics.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getContentAnalytics.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.dataContent = payload;
        });
        builder.addCase(getContentAnalytics.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const {setProjectNameAnalytics} = analyticsSlice.actions;

export default analyticsSlice.reducer;
