import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import removeIcon from '../../../assets/images/icon/remove.svg';
import settingsIcon from '../../../assets/images/icon/p3.svg';
import {IContentItemList} from '../store/types';
import {useAppDispatch} from '../../../hooks';
import {deleteContent} from '../store/project.slice';
import {Modal} from '../../../components';

const statusTypes = [
    {color: '#ffa500', name: 'В процессе'},
    {color: '#00E011FF', name: 'Озвучен'},
    {color: '#d62555', name: 'Ошибка '}
];

export const ProjectItem = (
    {
        id,
        title,
        created_at,
        status,
        symbols,
    }: IContentItemList
) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleGetBuId = () => {
        navigate(`/content/${id}/view`);
    };

    const handleDelete = () => {
        dispatch(deleteContent({id}));
        setOpen(false);
    };

    return (
        <div className="playerHistory_box content_box">
            <div className="playerHistory_block one_field">
                <p className="playerHistory_block-section">Заголовок статьи</p>
                <p className="playerHistory_block-in">{title}</p>
            </div>
            <div className="playerHistory_block two_field">
                <p className="playerHistory_block-section">Создан</p>
                <p className="playerHistory_block-in">{created_at}</p>
            </div>
            <div className="playerHistory_block">
                <p className="playerHistory_block-section">Символов</p>
                <p className="playerHistory_block-in">{symbols}</p>
            </div>
            <div className="playerHistory_block">
                <p className="playerHistory_block-section">Статус</p>
                <p className="playerHistory_block-in"
                   style={{color: statusTypes[status]?.color}}>{statusTypes[status]?.name}</p>
            </div>
            <div className="playerHistory_block playerHistory_block--button">
                <p className="playerHistory_block-section">Озвучка</p>
                <span className="playerHistory_block-remove" onClick={handleGetBuId}>
                    <img src={settingsIcon} alt={'icon'}/>
                </span>
            </div>
            <div className="playerHistory_block playerHistory_block--button">
                <p className="playerHistory_block-section">Удалить озвучку</p>
                <span className="playerHistory_block-remove" onClick={() => setOpen(true)}>
                    <img className="remove_unactiv" src={removeIcon} alt={'удалить'}/>
                </span>
            </div>
            <Modal isVisible={isOpen} handleClose={() => setOpen(!isOpen)}>
                <div className={'confirm-delete'}>
                    <p className={'confirm-delete__text'}>Точно требуется удалить эту озвучку?</p>
                    <div className={'confirm-delete__buttons'}>
                        <button
                            type={'button'}
                            className="playerCreater-generate"
                            onClick={handleDelete}
                        >Да
                        </button>
                        <button
                            type={'button'}
                            className="playerCreater-generate"
                            onClick={() => setOpen(false)}
                        >Нет
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
