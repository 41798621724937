import React, {useState} from 'react';
import {CheckedIcon} from '../assets/customIcons';
import {lineEndingCheckOnlyHttps} from '../helpers/lineEndingCheck';

interface ISearchProps {
    placeholder: string;
    data: any[];
    handler: (value: string) => void;
    onBlur:(value:string) => void;
}

export const InputSearch = ({placeholder, data, handler, onBlur}: ISearchProps) => {

    const [value, setValue] = useState<string>('');

    const handleSearch = (value: string) => {
        setValue(value.trim());
    };

    return (
        <div className={'search'}>
            <div className={'playerCreater_item-form-box'}>
                <div className={'input-wrapper'}>
                    <input
                        placeholder={placeholder}
                        value={value}
                        onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                lineEndingCheckOnlyHttps(value)
                                && handler(value.trim());
                            }
                        }}
                        onChange={
                            (e: React.ChangeEvent<HTMLInputElement>) =>
                                handleSearch(e.target.value)
                        }
                        onBlur={() => onBlur(value.trim())}
                    />
                    <div className={'input-wrapper-checked'}>
                        <CheckedIcon color={lineEndingCheckOnlyHttps(value) ? '#00E011FF' : '#565656'}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
