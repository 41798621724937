import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import './styles.css';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {getCategory, getLanguage, getVoices} from '../../store/app.slice';
import {getMyProjects} from '../projects/store/project.slice';
import {Projects, Tariffs} from './components';

export const HomePage = () => {
    const dispatch = useAppDispatch();

    const {voices, languages, categories} = useAppSelector(state => state.app);
    const {loader} = useAppSelector(state => state.project);

    useEffect(() => {
        if (voices.length === 0 && languages.length === 0 && categories.length === 0) {
            dispatch(getCategory());
            dispatch(getVoices());
            dispatch(getLanguage());
        }
        dispatch(getMyProjects());
    }, [voices, categories, languages, dispatch]);

    return (
        <MainLayout
            loader={loader}
            header={<Header/>}
            content={
                <div className="playerHistory">
                    <div className="playerHistory_center">
                        <p className="playerHistory-text">
                            Работает с любым сайтом
                        </p>

                        <h2 className="playerHistory-title">
                            Голосовая озвучка сайта
                        </h2>

                        <div className="playerHistory_row">
                            <Projects/>
                            <Tariffs/>
                        </div>
                    </div>
                </div>
            }
            footer={<Footer/>}
        />
    );
};
