import React, {ChangeEvent, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import {MainLayout} from '../../layouts';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import addressIcon from '../../assets/images/icon/addres.svg';
import LangIcon from '../../assets/images/icon/lang.svg';
import rssLinkIcon from '../../assets/images/icon/rss-link.svg';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {ColorSettings} from '../home/components';
import {Modal} from '../../components';
import {FormInput, FormSelector} from '../../components/formComponents';
import {Selector, selectorSize} from '../../fields/Selector';
import {typeValidationEnum} from '../../store/types';
import {AudioPlayer} from '../../components/audioPlayer';
import {IOptions} from '../../components/audioPlayer/types';
import {updateProject} from './store/project.slice';
import {IIntegrationRequest} from './store/types';
const defaultVoiceFile = require('../../assets/images/Kalimba.mp3');

const schema = yup
    .object({
        projectName: yup.string().required(),
        type: yup.string().required(),
        url: yup.string().required(),
        voice: yup.string().required(),
        lang: yup.string(),
        namePlayer: yup.string(),
        valuePlayer: yup.string(),
    });

const options: IOptions[] = [

    {name: 'По умолчанию', value: 'watermark'},
    {name: 'Стандартный', value: 'default'},
    {name: 'Минималистичный', value: 'first'},
    {name: 'Плеер с обводкой', value: 'second'},
    {name: 'Узкий плеер', value: 'three'},
];

export const ProjectCreatePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {voices, languages, categories} = useAppSelector(state => state.app);
    const {data, loader} = useAppSelector(state => state.project);

    const [radius, setRadius] = useState<string>(data.player.border_radius.toString());
    const [background, setBackground] = useState<string>(data.player.background_color);
    const [border, setBorder] = useState<string>(data.player.border_color);
    const [buttonColor, setButtonColor] = useState<string>(data.player.main_color);
    const [selectedPlayer, setSelectedPlayer] = useState<IOptions>({name: options[0].name, value: options[0].value});

    const [isVisible, setVisible] = useState<boolean>(false);

    const voiceItems = voices.map(i => i.voice);
    const languageItems = languages.map(i => i.language);
    const categoriesItems = categories.map(i => i.title);
    const defaultVoice = voices.filter(i => i.voice_name === data.integration.voice).map(i => i.voice)[0];
    const defaultType = categories.filter(i => i.id === data.integration.type).map(i => i.title)[0];

    useEffect(() => {
        const type = options.filter(i => i.value === data.player.type_player).pop();
        if(type) {
            setSelectedPlayer(type);
        }
    },[data]);



    const {register, handleSubmit, watch, control, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            projectName: data.project.name,
            url: data.integration.url,
            voice: defaultVoice,
            type: defaultType,
            lang: languageItems[0],
            type_player: data.player.type_player
        },
        mode: 'all',
        shouldUnregister: false,
    });
    const watchUrl = watch('url');

    const handleGetCodePlayer = () => {
        setVisible(true);
    };

    const handleSelectPlayer = (text) => {
        const currentPlayer = options.filter(i => i.name === text).pop();
        setSelectedPlayer(currentPlayer);
    };

    const submitUpdate = handleSubmit(values => {
        const req: IIntegrationRequest = {
            projectName: values.projectName.trim(),
            project_id: data.integration.project_id,
            integration: {
                type: categories.filter(i => i.title === values.type).map(i => i.id)[0].trim(),
                voice: voices.filter(i => i.voice === values.voice).map(i => i.voice_name)[0].trim(),
                url: values.url.trim()
            },
            player: {
                project_id: data.integration.project_id,
                title: '',
                padding: 0,
                border_radius: Number(radius),
                main_color: buttonColor,
                text_color: '#000000',
                title_color: '#000000',
                border_color: border,
                background_color: background,
                type_player: selectedPlayer.value
            }
        };

        dispatch(updateProject({...req}));
    });

    return (
        <MainLayout
            loader={loader}
            header={<Header/>}
            content={
                <div className="playerCreater">
                    <div className="playerCreater_center">
                        <p className="playerCreater-text">
                            Работает с любым сайтом
                        </p>
                        <h2 className="playerCreater-title">
                            Голосовая озвучка сайта
                        </h2>
                        <div className="playerCreater_row">
                            <div className="playerCreater_item">
                                <p className="playerCreater_item-sectionTitle">
                                    <a className="breadcumb_url" href={'/'}>Мои проекты</a> ➔
                                    <span className="breadcumb_current"> Добавление сайта</span>
                                </p>

                                <form className="playerCreater_item-form">
                                    <div className="playerCreater_item-form-label">
                                        <div className="playerCreater_item-form-box">
                                         <span>
                                              <img src={addressIcon} alt={'icon'}/>
                                              Имя проекта
                                          </span>
                                            <div className={'input-wrapper'}>
                                                <FormInput
                                                    register={register}
                                                    error={errors.projectName}
                                                    watch={watchUrl}
                                                    name={'projectName'}
                                                    control={control}
                                                    showChecked={true}
                                                    typeValidation={typeValidationEnum.url}
                                                />
                                            </div>
                                        </div>
                                        <div className="playerCreater_item-text">
                                            <span>Произвольное название, оно будет отображаться в списке ваших проектов. Рекомендуем ввести имя домена.</span>
                                        </div>
                                    </div>
                                    <div className="playerCreater_item-form-label">
                                        <div className="playerCreater_item-form-box">
                                         <span>
                                             <img src={rssLinkIcon} alt={'icon'}/>
                                             Ссылка на RSS Ленту
                                         </span>
                                            <FormInput
                                                name={'url'}
                                                error={errors?.url}
                                                watch={watchUrl}
                                                showChecked={true}
                                                control={control}
                                                register={register}
                                                typeValidation={typeValidationEnum.onlyHttps}
                                            />
                                        </div>
                                        <div className="playerCreater_item-text">
                                            <span>Ссылка на RSS ленту вашего сайта, отсюда будут браться статьи для озвучки. Лента должна проходить проверку в сервисе: validator.w3.org/feed/ </span>
                                        </div>
                                    </div>
                                    <div className="playerCreater_item-form-label">
                                        <div className="playerCreater_item-form-box maxsize">
                                         <span>
                                             <img src={LangIcon} alt={'icon'}/>
                                             Категория проекта
                                         </span>
                                        <FormSelector
                                            label={'Тип озвучки'}
                                            name={'type'}
                                            error={errors?.type}
                                            options={categoriesItems}
                                            control={control}
                                            size={selectorSize.xl}
                                        />
                                            </div>
                                        <div className="playerCreater_item-text">
                                            <span>Выберите категорию которая больше подходит вашему проекту.</span>
                                        </div>
                                    </div>
                                    <div className="playerCreater_item-form-label">
                                        <div className="playerCreater_item-form-box maxsize">
                                         <span>
                                             <img src={LangIcon} alt={'icon'}/>
                                             ЯЗЫК ОЗВУЧКИ
                                         </span>
                                        <FormSelector
                                            label={'Язык озвучки'}
                                            name={'lang'}
                                            error={errors?.lang}
                                            options={languageItems}
                                            control={control}
                                            size={selectorSize.xl}

                                        />
                                            </div>
                                        <div className="playerCreater_item-text">
                                            <span>Фильтр голосов по языку</span>
                                        </div>
                                    </div>
                                    <div className="playerCreater_item-form-label">
                                        <div className="playerCreater_item-form-box maxsize">
                                         <span>
                                             <img src={LangIcon} alt={'icon'}/>
                                             ГОЛОС ОЗВУЧКИ
                                         </span>
                                        <FormSelector
                                            label={'Голос озвучки'}
                                            name={'voice'}
                                            error={errors?.voice}
                                            options={voiceItems}
                                            control={control}
                                            size={selectorSize.xl}

                                        />
                                        </div>
                                        <div className="playerCreater_item-text">
                                            <span>Голос которым будет озвучен контент проекта</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="playerCreater_item">
                                <p className="playerCreater_item-sectionTitle">
                                    Настройки плеера
                                </p>
                                <AudioPlayer
                                    background_color={background}
                                    main_color={buttonColor}
                                    file={defaultVoiceFile}
                                    border_color={border}
                                    border_radius={radius}
                                    type_player={selectedPlayer?.value}
                                />

                                <div className="playerCreater_item-form-label">
                                    <div className="wrapper-custom-select" >
                                        <Selector
                                            size={selectorSize.xl}
                                            label={'Выбор плеера'}
                                            items={options.map(i => i.name)}
                                            defaultValue={selectedPlayer.name}
                                            handler={handleSelectPlayer}/>
                                        <div className="playerCreater_item-text tipcenter">
                                            <span>Выберите тип плеера который будет отображаться у Вас на сайте.</span>
                                        </div>
                                    </div>
                                </div>

                                {/*<Player*/}
                                {/*    radius={radius}*/}
                                {/*    borderColor={border}*/}
                                {/*    controlColor={buttonColor}*/}
                                {/*    background={background}*/}
                                {/*    horizontal={false}*/}
                                {/*    jumpControls={true}*/}
                                {/*/>*/}
                                <ColorSettings
                                    background={background}
                                    setBackground={setBackground}
                                    border={border}
                                    setBorder={setBorder}
                                    buttonColor={buttonColor}
                                    setButtonColor={setButtonColor}
                                />
                                <div className="playerCreater_box">
                                    <p className="playerCreater_box-title">Радиус углов плеера</p>
                                    <div className="playerCreater_box-settings">
                                        <p>
                                            <input
                                                type="number"
                                                name="radius"
                                                value={radius}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setRadius(e.target.value)}/>
                                            Радиус
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'wrapper-button'}>
                            <button
                                type={'button'}
                                className="playerCreater-generate"
                                disabled={!_.isEmpty(errors)}
                                onClick={submitUpdate}>
                                Обновить
                            </button>
                            <button
                                type={'button'}
                                className="playerCreater-generate"
                                onClick={handleGetCodePlayer}>
                                Получить код плеера
                            </button>
                            <button
                                type={'button'}
                                className="playerCreater-generate"
                                onClick={() => navigate(-1)}>
                                Назад
                            </button>
                        </div>
                    </div>
                    <Modal isVisible={isVisible} handleClose={() => setVisible(!isVisible)}>

                        <div style={{minHeight: '300px', alignItems: 'center'}}>
                            <div className={'confirm-delete'}>
                                <p className={'confirm-delete__text'} style={{paddingTop: '100px', userSelect: 'none'}}>СКОПИРУЙТЕ ДАННЫЙ КОД ДЛЯ УСТАНОВКИ НА САЙТ:</p>
                            </div>
                            <p style={{color: '#ffffff', fontWeight: 'bold'}}><div style={{textAlign: 'center'}}>
                                {'<script src="https://api.apihost.ru/remote.js"></script>'}
                                <br/>
                                {'<div class="rss-content-audio"></div>'}
                            </div>
                            </p>
                        </div>

                    </Modal>
                </div>
            }
            footer={<Footer/>}
        />
    );
};
