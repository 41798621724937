import axios, {AxiosError, AxiosInstance, AxiosRequestConfig,} from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const tokenStorage = localStorage.getItem('token');

    if (tokenStorage && config?.headers) {
        const token = JSON.parse(tokenStorage);
        config.headers['Authorization'] = `Bearer ${token.access_token}`;
    }


    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
};

export async function refreshToken(refresh_token: string) {
    const rs = await axios.post(`${API_URL}/token/refresh`, {
        refresh_token: refresh_token,
    });
    const {access_token, access_token_expires_at} = rs.data.data;
    localStorage.setItem('token', JSON.stringify({access_token, refresh_token, access_token_expires_at}));
}

export async function checkToken() {
    const tokens = localStorage.getItem('token');

    if (tokens) {
        const {access_token_expires_at, refresh_token} = JSON.parse(tokens);

        if ((new Date().getTime() - new Date(access_token_expires_at).getTime()) / 60000 > -2) {
            await refreshToken(refresh_token);
            return;
        }
    }
    return;
}


export const setupInterceptorsTo = (
    axiosInstance: AxiosInstance
): AxiosInstance => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    // axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};

const api = setupInterceptorsTo(
    axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    })
);
export default api;
