import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ErrorMessage} from '@hookform/error-message';
import {MainLayout} from '../../layouts';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {selectorSize} from '../../fields/Selector';
import addressIcon from '../../assets/images/icon/addres.svg';
import {FormInput, FormSelector} from '../../components/formComponents';
import {typeValidationEnum} from '../../store/types';
import rssLinkIcon from '../../assets/images/icon/rss-link.svg';
import {createContent} from './store/project.slice';
import {ICreateContentData} from './store/types';

const schema = yup.object({
    title: yup.string(),
    voice: yup.string().required('Выберите голос для озвучки'),
    content: yup.string().min(100, 'Минимум 100 символов').required(),
    url: yup.string().required('Это поле обязательно')
});

export const CreateVoice = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id = ''} = useParams();
    const {
        project_id
    } = useAppSelector(state => state.project.content);
    const {voices, languages} = useAppSelector(state => state.app);
    const {loader} = useAppSelector(state => state.project);

    const voiceItems = voices.map(i => i.voice);
    const languageItems = languages.map(i => i.language);

    const {control, register, watch, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema)
    });
    const watchUrl = watch(['title', 'url', 'voice']);

    const onSubmit = handleSubmit(values => {
        const data: ICreateContentData = {
            title: values.title.trim(),
            voice: voices.filter(i => i.voice === values.voice).map(i => i.voice_name)[0].trim(),
            content: values.content.trim(),
            url: values.url.trim()
        };

        dispatch(createContent({data, id: id, navigate}));
    });

    return (
        <MainLayout
            loader={loader}
            header={<Header/>}
            content={
                <div className="playerCode content-page">
                    <div className="playerCode_center">
                        <p className="playerCode-text">
                            Работает с любым сайтом
                        </p>
                        <h2 className="playerCode-title">
                            Голосовая озвучка сайта
                        </h2>

                        <div className="playerCode_row">

                                    <p className="playerCreater_item-sectionTitle">
                                        <a className="breadcumb_url" href={'/'}>Мои проекты</a> ➔ <a
                                        className="breadcumb_url" href={`/content/${project_id}`}>Контент</a> ➔
                                        <span className="breadcumb_current"> Создать озвучку</span>
                                    </p>

                            <form className="playerCreater_item-form create-voice-wrapper">
                                <div className="playerCode_item alignstart">
                                    <div className="playerCreater_item-form-label">
                                        <div className="playerCreater_item-form-box">
                                         <span>
                                              <img src={addressIcon} alt={'icon'}/>
                                              Название статьи
                                          </span>
                                            <FormInput
                                                control={control}
                                                register={register}
                                                error={errors.title}
                                                watch={watchUrl[0]}
                                                name={'title'}
                                                typeValidation={typeValidationEnum.url}
                                                showChecked={true}/>
                                        </div>

                                        <div className="playerCreater_item-text">
                                            <span>Название статьи которое будет отображатсья в списке.</span>
                                        </div>
                                    </div>
                                    <div className="playerCreater_item-form-label">
                                        <div className="playerCreater_item-form-box">
                                         <span>
                                              <img src={rssLinkIcon} alt={'icon'}/>
                                               Страница отображения
                                          </span>
                                            <FormInput
                                                control={control}
                                                register={register}
                                                error={errors.url}
                                                watch={watchUrl[1]}
                                                name={'url'}
                                                showChecked={true}
                                                typeValidation={typeValidationEnum.onlyHttps}
                                            />
                                        </div>
                                        <ErrorMessage
                                            errors={errors}
                                            name="url"
                                            render={({message}) =>
                                                <p style={{color: 'red', fontSize: '12px'}}>{message}</p>
                                            }/>
                                        <div className="playerCreater_item-text">
                                            <span>Ссылка на страницу на которой будет показан плеер с озвучкой</span>
                                        </div>
                                    </div>
                                    <div className="playerCode_item-textarea">
                                        <ErrorMessage
                                            errors={errors}
                                            name="content"
                                            render={({message}) =>
                                                <p style={{color: 'red', fontSize: '12px'}}>{message}</p>
                                            }/>
                                        <ErrorMessage
                                            errors={errors}
                                            name="voice"
                                            render={({message}) =>
                                                <p style={{color: 'red', fontSize: '12px'}}>{message}</p>
                                            }/>

                                        <div
                                            className="display_item_voice_parametrs"
                                            style={{borderColor: errors?.content ? 'red' : 'rgba(255, 255, 255, 0.06)'}}>
                                            <div className="display_item_voice_parametrs_selects select-group">
                                                <FormSelector
                                                    label={'Русский'}
                                                    control={control}
                                                    options={languageItems}
                                                    error={errors.lang}
                                                    name={'lang'}
                                                    size={selectorSize.lg}
                                                />
                                                <FormSelector
                                                    control={control}
                                                    options={voiceItems}
                                                    error={errors.voice}
                                                    name={'voice'}
                                                    label={'Голос'}
                                                    size={selectorSize.lg}
                                                />
                                            </div>
                                            <div className="display_item_voice_parametrs_textarea">
                                                <textarea
                                                    placeholder={'Введите текст статьи который требуется озвучить.                               Вы можете менять ударение знаком плюс.                                           Например: Оля, или Ол+я, замок или зам+ок.'}
                                                    {...register('content')}
                                                />
                                            </div>
                                        </div>
                                        <div className="display_item_voice_parametrs_controler">
                                            <div className="display_item_voice_parametrs_controler-box">
                                                <button
                                                    id="next"
                                                    style={{color: 'rgb(145, 148, 151)', border: 'none!important'}}
                                                    type={'button'}
                                                    onClick={onSubmit}
                                                >
                                                    <img
                                                        id="iconplay" src="https://apihost.ru/images/icon/play_1.svg"
                                                        alt={'icon'}
                                                        style={{filter: 'contrast(0.1)'}}/>
                                                    Озвучить
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <span className="playerCreater-generate" onClick={() => navigate(-1)}>Назад</span>
                    </div>
                </div>
            }
            footer={<Footer/>}
        />
    );
};
