import React from 'react';
import {IIconProps} from './types';


export const Play = ({color = '#55606E', type_player, border_color}: IIconProps) => {
  if (type_player === 'first') {
        return (
            <svg viewBox="0 0 32 32" width="30" height="30">
                <g id="Play">
                    <path fill={color}
                          d="M26.78,13.45,11.58,4A3,3,0,0,0,7,6.59V25.41a3,3,0,0,0,3,3A3,3,0,0,0,11.58,28l15.2-9.41a3,3,0,0,0,0-5.1Z"/>
                </g>
            </svg>);

    } else if (type_player === 'second') {
        return (

            <svg version="1.1" id="Capa_1" x="0px" y="0px"
                 viewBox="0 0 58 58" width="35" height="35" fill="enable-background:new 0 0 58 58;">
                <circle fill={border_color} cx="29" cy="29" r="29"/>
                <g>
                    <polygon fill={color} points="44,29 22,44 22,29.273 22,14 	"/>
                    <path fill={color} d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
        c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
        l-22,15C22.394,44.941,22.197,45,22,45z M23,15.893v26.215L42.225,29L23,15.893z"/>
                </g>
            </svg>
        );
    } else if (type_player === 'three') {
        return (

            <svg viewBox="0 0 32 32" width="22" height="22">
                <g id="Play">
                    <path fill={color}
                          d="M26.78,13.45,11.58,4A3,3,0,0,0,7,6.59V25.41a3,3,0,0,0,3,3A3,3,0,0,0,11.58,28l15.2-9.41a3,3,0,0,0,0-5.1Z"/>
                </g>
            </svg>
        );
    }
    return (
        <svg viewBox="0 0 32 32" width="25" height="25">
            <g id="Play">
                <path fill={color}
                      d="M26.78,13.45,11.58,4A3,3,0,0,0,7,6.59V25.41a3,3,0,0,0,3,3A3,3,0,0,0,11.58,28l15.2-9.41a3,3,0,0,0,0-5.1Z"/>
            </g>
        </svg>)

};
