import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {MainLayout} from '../../layouts';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {CustomDatePicker} from '../../components/datePicker';
import {formatDateString} from '../../helpers/formatDateString';
import {Item} from './components';
import './style.css';
import {getContentAnalytics} from './store/analytics.slice';

const date = new Date();
const defaultDate = formatDateString(date);

export const AnalyticsContent = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {loading, dataContent, projectName} = useAppSelector(state => state.analytics);
    const {click, view, heard} = dataContent;
    const [date, setDate] = useState<string>(defaultDate);

    useEffect(() => {
        dispatch(getContentAnalytics({id, date: defaultDate}));
    }, []);

    const handleBack = () => {
        navigate('/');

    };

    const handleChangeDate = (date) => {
        setDate(date);
        dispatch(getContentAnalytics({id, date: date}));
    };

//Content
    return (
        <MainLayout
            loader={loading}
            header={<Header/>}
            content={
                <div className="playerHistory analytics">
                    <div className="playerHistory_center">


                            <p className="playerCode-text">
                                Работает с любым сайтом
                            </p>
                            <h2 className="playerCode-title">
                                Голосовая озвучка сайта
                            </h2>
                            <div className="playerHistory_row">
                            <p className="playerCode_item-sectionTitle">
                                <a className="breadcumb_url" href={'/'}>Мои проекты</a> ➔ <a
                                className="breadcumb_url" href={`/content/${''}`}>Контент</a> ➔
                                <span className="breadcumb_current">Статистика по контенту</span>
                            </p>

                                <div className={'playerHistory-toolbar__buttons'}>
                                    <button
                                        type={'button'}
                                        className="playerCreater-generate"
                                        onClick={handleBack}
                                    >Назад
                                    </button>

                                <div className={'playerHistory-toolbar'}>
                                <CustomDatePicker handleChangeDate={handleChangeDate} />
                                 </div>
                            </div>
                            <Item date={date} click={click} view={view} heard={heard} projectName={projectName} />

                        </div>
                    </div>
                </div>
            }
            footer={<Footer/>}/>
    );
};
