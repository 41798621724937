export const lineEndingCheck = (str: string) => {
    if (str?.includes('https://') && str?.includes('.ru')) {
        return true;
    }
    if (str?.includes('https://') && str?.includes('.com')) {
        return true;
    }
    return false;
};

export const lineLength = (str: string)  => {
    return str?.length > 5;
}

export const lineEndingCheckOnlyHttps = (str:string) => {
    if (str?.includes('https://')) {
        return true;
    }
    return false;
};
