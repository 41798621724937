import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {alertTypes, showAlert} from '../../../store/app.slice';
import api, {checkToken} from '../../../api';
import {handleError} from '../../../helpers/handleError';
import {
    IById,
    IContent,
    IContentItemList,
    IContentListResponse,
    ICreateContentData,
    IIntegrationRequest,
    IProject,
    IProjectList,
    IProjectListItem,
    IProjectResponse
} from './types';


interface ICreateProjectResponse {
    data: {
        id: string;
    }
}

interface IState {
    data: IProject;
    projectList: IProjectListItem[];
    contentList: IContentItemList[];
    content: IContent;
    loader: boolean;
    nextCursor: string;
}

export const initContent = {
    id: '',
    project_id: '',
    title: '',
    symbols: 0,
    mp3: '',
    m3u8: '',
    source: '',
    url: '',
    status: 0,
    created_at: '',
    content: '',
    voice: ''
};

const initialState: IState = {
    data: {
        project: {
            id: '',
            name: '',
            webhook_url: '',
        },
        integration: {
            project_id: '',
            type: '',
            voice: '',
            url: '',
        },
        player: {
            project_id: '',
            title: '',
            padding: 0,
            border_radius: 0,
            main_color: '',
            text_color: '#000000',
            title_color: '',
            border_color: '',
            background_color: '',
            type_player: ''
        }
    },
    projectList: [],
    contentList: [],
    content: initContent,
    loader: false,
    nextCursor: ''
};


export const createProject = createAsyncThunk(
    'project/createProject',
    async ({name, navigate}: { name: string, navigate: any }, {dispatch}) => {
        await checkToken();
        try {
            const res = await api.post<ICreateProjectResponse>('/project/create', {name});
            const {id} = await res.data.data;

            const response = await api.get<IProjectResponse>(`/project/info/${id}`);
            navigate(`/project/${id}/update`);
            return response.data.data;
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const updateProjectName = createAsyncThunk(
    'project/updateProjectName',
    async ({name, id}: { name: string, id: string }, {dispatch}) => {
        await checkToken();
        try {
            const res = await api.put<IProjectResponse>(`/project/name/${id}`, {name});
            dispatch(showAlert({type: alertTypes.SUCCESS, message: 'Успешно обновили имя проекта', show: true}));
            return res.data.data;
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const getMyProjects = createAsyncThunk(
    'project/getMyProjects',
    async (_, {dispatch}) => {
        await checkToken();
        try {
            const res = await api.get<IProjectList>('project/list');
            return !res.data?.length ? res.data.data : [];
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const getProjectById = createAsyncThunk(
    'project/getProjectById',
    async ({id, navigate}: IById, {dispatch}) => {
        await checkToken();
        try {
            const res = await api.get<IProjectResponse>(`/project/info/${id}`);
            navigate(`/project/${id}/update`);
            return res.data.data;
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const updateProject = createAsyncThunk(
    'project/updateProject',
    async ({project_id, player, integration, projectName}: IIntegrationRequest, {dispatch}) => {
        await checkToken();
        try {
            await api.put(`/integration/update/${project_id}`, integration,);

            await api.put(`/player/update/${project_id}`, player);

            await api.put<IProjectResponse>(`/project/name/${project_id}`, {name: projectName});

            dispatch(showAlert({type: alertTypes.SUCCESS, message: 'Успешно обновили', show: true}));
            return;
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const projectDelete = createAsyncThunk(
    'project/projectDelete',
    async ({id}: { id: string }, {dispatch}) => {
        await checkToken();
        try {
            const res = await api.delete<IProjectResponse>(`/project/delete/${id}`);
            dispatch(showAlert({type: alertTypes.SUCCESS, message: 'Успешно удалено', show: true}));
            dispatch(getMyProjects());
            return res.data.data;
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const getByProjectId = createAsyncThunk(
    'project/getByProjectId',
    async ({id, navigate}: IById, {dispatch, getState}) => {
        await checkToken();
        try {
            const res = await api.get<IContentListResponse>(`/content/list/${id}`);
            navigate && navigate(`/content/${id}`);
            return res.data ?? [];
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const getContentByProjectId = createAsyncThunk(
    'project/getContentByProjectId',
    async ({id, navigate}: IById, {dispatch, getState}) => {
        await checkToken();
        try {
            const {project} = getState() as any;
            const {nextCursor} = project;
            const config = nextCursor.length > 0 ? {
                params: {nextCursor: nextCursor}
            } : {};
            const res = await api.get<IContentListResponse>(`/content/list/${id}`, config);
            navigate && navigate(`/content/${id}`);
            return res.data ?? [];
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const getContentById = createAsyncThunk(
    'project/getContentById',
    async ({id}: IById, {dispatch}) => {
        await checkToken();
        try {
            const res = await api.get<IContentListResponse>(`/content/${id}`);
            return res.data.data ?? [];
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const getContentByFilter = createAsyncThunk(
    'project/getContentByFilter',
    async ({id, value, nextCursor}: { value: string, id: string, nextCursor: string }, {dispatch}) => {
        await checkToken();
        try {
            const res = await api.get(`/content/search/${id}?q=${value}`);

            return res.data;
        } catch (e) {
            handleError(e, dispatch);
        }
    }
);

export const createContent = createAsyncThunk(
    'project/createContent',
    async ({data, id, navigate}: { data: ICreateContentData, id: string, navigate: any }, {dispatch}) => {
        await checkToken();
        try {
              await api.post(`/content/create/${id}`, data);

                dispatch(showAlert({type: alertTypes.SUCCESS, message: 'Успешно создали озвучку', show: true}));
                const config = {
                    params: {nextCursor: ''}
                };
                const response = await api.get<IContentListResponse>(`/content/list/${id}`, config);
                navigate(`/content/${id}`);
                return response.data ?? [];

        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

export const updateContent = createAsyncThunk(
    'project/updateContent',
    async ({data, id, navigate}:{data:ICreateContentData , id:string, navigate: any}, {dispatch}) => {
        await checkToken();
        try {
              await api.put(`/content/update/${id}`, data);
            dispatch(showAlert({type: alertTypes.SUCCESS, message: 'Успешно обновили', show: true}));
        } catch (e) {
            handleError(e, dispatch);
        }
    }
);

export const deleteContent = createAsyncThunk(
    'project/deleteContent',
    async ({id}: { id: string }, {dispatch, getState}) => {
        await checkToken();
        try {
            const {project} = getState() as any;
            const {contentList} = project;
            dispatch(clearItemContent([]));
            await api.delete(`/content/delete/${id}`);
            dispatch(showAlert({type: alertTypes.SUCCESS, message: 'Успешно удалено', show: true}));
            if (contentList.length > 1) {
                const {project} = getState() as any;
                const {nextCursor} = project;
                const config = nextCursor.length > 0 ? {
                    params: {nextCursor: nextCursor}
                } : {};
                const res = await api.get<IContentListResponse>(`/content/list/${contentList[0].project_id}`, config);
                return res.data ?? [];
            } else {
                return [];
            }
        } catch (e: any) {
            handleError(e, dispatch);
        }
    }
);

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        // setProjectName: (state, {payload}) => {
        //     state.data.project.name = payload;
        // },
        clearContentList: (state) => {
            state.contentList = [];
        },
        clearItemContent: (state, {payload}) => {
            state.content = payload;
            state.nextCursor = '';
        },
    },
    extraReducers: builder => {
        builder.addCase(createProject.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(createProject.fulfilled, (state, {payload}: { payload: any }) => {
            state.loader = false;
            state.data.project = payload.project;
            state.data.integration = payload.integration;
            state.data.player = payload.player;
        });
        builder.addCase(getProjectById.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(getProjectById.fulfilled, (state, {payload}: { payload: any }) => {
            state.loader = false;
            state.data.project = payload.project;
            state.data.integration = payload.integration;
            state.data.player = payload.player;
        });
        builder.addCase(updateProject.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(updateProject.fulfilled, (state) => {
            state.loader = false;
        });
        builder.addCase(updateProject.rejected, (state) => {
            state.loader = false;
        });
        builder.addCase(getMyProjects.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(getMyProjects.fulfilled, (state, {payload}: { payload: any }) => {
            state.loader = false;
            state.projectList = payload;
        });
        builder.addCase(getMyProjects.rejected, (state) => {
            state.loader = false;
            state.projectList = [];
        });

        builder.addCase(getByProjectId.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(getByProjectId.fulfilled, (state, {payload}: { payload: any }) => {
            state.loader = false;
            state.contentList = payload.data;
            state.nextCursor = payload.nextCursor ?? '';
        });
        builder.addCase(getByProjectId.rejected, (state) => {
            state.loader = false;
            state.projectList = [];
        });

        builder.addCase(getContentByProjectId.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(getContentByProjectId.fulfilled, (state, {payload}: { payload: any }) => {
            state.loader = false;
            state.contentList = [...state.contentList, ...payload.data ?? []];
            state.nextCursor = payload.nextCursor ?? '';
        });
        builder.addCase(getContentByProjectId.rejected, (state) => {
            state.loader = false;
            state.projectList = [];
        });

        builder.addCase(getContentByFilter.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(getContentByFilter.fulfilled, (state, {payload}: { payload: any }) => {
            state.loader = false;
            state.contentList = payload.data ?? [];
            state.nextCursor = payload.nextCursor ?? '';
        });
        builder.addCase(getContentByFilter.rejected, (state) => {
            state.loader = false;
            state.projectList = [];
        });

        builder.addCase(deleteContent.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(deleteContent.fulfilled, (state, {payload}: { payload: any }) => {
            state.loader = false;
            state.contentList = payload.data ?? [];
            state.nextCursor = payload.nextCursor ?? '';
        });
        builder.addCase(deleteContent.rejected, (state) => {
            state.loader = false;
            state.projectList = [];
        });

        builder.addCase(getContentById.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(getContentById.fulfilled, (state, {payload}: { payload: any }) => {
            state.loader = false;
            state.content.id = payload.id;
            state.content.project_id = payload.project_id;
            state.content.voice = payload.voice;
            state.content.title = payload.title;
            state.content.content = payload.content;
            state.content.symbols = payload.symbols;
            state.content.mp3 = payload.mp3;
           // state.content.m3u8 = payload.m3u8;
            state.content.source = payload.source;
            state.content.url = payload.url;
            state.content.status = payload.status;
            state.content.created_at = payload.created_at;
        });
        builder.addCase(getContentById.rejected, (state) => {
            state.loader = false;
        });
        builder.addCase(updateProjectName.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(updateProjectName.fulfilled, (state, {payload}: any) => {
            state.loader = false;
            state.data.project.name = payload;
        });
        builder.addCase(updateProjectName.rejected, (state, {payload}: any) => {
            state.loader = false;
        });
        builder.addCase(createContent.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(createContent.fulfilled, (state, {payload}: any) => {
            state.loader = false;
            state.contentList = payload.data ?? [];
            state.nextCursor = payload.nextCursor ?? '';
        });
        builder.addCase(createContent.rejected, (state, {payload}: any) => {
            state.loader = false;
        });
        builder.addCase(updateContent.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(updateContent.fulfilled, (state ) => {
            state.loader = false;
        });
        builder.addCase(updateContent.rejected, (state, {payload}: any) => {
            state.loader = false;
        });
    }
});

export const {
    // setProjectName,
    clearContentList,
    clearItemContent
} = projectSlice.actions;

export default projectSlice.reducer;
