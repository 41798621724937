import {alertTypes, showAlert} from '../store/app.slice';

export const handleError = (e: any, dispatch: any) => {
    if (e?.response?.status === 401) {
        return;
    }
    const {response} = e;
    dispatch(showAlert({
        type: alertTypes.ERROR,
        message: response?.data?.message
            ? response.data.message
            : response.data.error_message,
        show: true
    }));

    throw Error();
};
