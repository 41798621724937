import {useEffect, useState} from 'react';

export const useWindowResize = () => {
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        const width = window.innerWidth;
        setWidth(width);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', function () {
            const width = this.innerWidth;
            setWidth(width);
        });
    }, []);

    return {width};
};
