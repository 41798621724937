import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Controls, CurrentTime, Duration, ProgressBar, Volume} from './components';
import './styles/main.css';


interface IAudioPlayer {
    type_player?: string;
    main_color?: string;
    border_color?: string;
    border_radius?: string;
    background_color?: string;
    file?: string;
}

function Element(type_player: string) : JSX.Element | string {

    return type_player === 'watermark' ? <a className="watermark" target="_blank" rel="noreferrer" href="https://audio.apihost.ru" >Озвученно Apihost</a>: '';
}

export const AudioPlayer = (
    {
        file,
        type_player,
        background_color,
        main_color,
        border_color,
        border_radius
    }: IAudioPlayer
) => {


    const player = useRef<HTMLAudioElement>(null);

    const [audio, setAudio] = useState<HTMLAudioElement>();
    const [showPlayer, setShowPLayer] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [volume, setVolume] = useState<number>(1);

    useEffect(() => {

        if (player) {
            player.current.addEventListener('timeupdate', function () {
                const progress = this.currentTime;
                setProgress(progress);
                setCurrentTime(this.currentTime);
            });
        }
    }, [audio]);

    /*useEffect(() => {

        if(type_player === 'watermark') {
            const watermark = document.getElementById('watermark');
               watermark.style.opacity = '1';
        } else {
            const watermark = document.getElementById('watermark');
            watermark.style.opacity = '0';
        }

    }, []);*/

    const handleInit = () => {
        setAudio(player.current);
        setShowPLayer(true);
    };

    const handleControl = async () => {
        !audio?.paused
            ? audio.pause()
            : await audio.play();
    };

    const handleProgressClick = (value: number) => {
        audio.currentTime = value;
        setProgress(value);
    };

    const renderCurrentTime = useMemo(
        () =>
            <CurrentTime time={currentTime}/>,
        [currentTime]);

    const renderDuration = useMemo(
        () =>
            <Duration
                time={audio?.duration ?? 0 - currentTime}
            />,
        [currentTime, audio]);

    return (
        <div className={`main-wrapper-audio-${type_player}`}
             style={{
                 backgroundColor: background_color,
                 border: `1px solid ${border_color}`,
                 borderRadius: `${border_radius}px`
             }}>
            <audio
                ref={player}
                src={file}
                style={{display: 'none'}}
                onCanPlay={handleInit}
            />
            <Controls
                handleControl={handleControl}
                isPaused={audio?.paused}
                main_color={main_color}
                type_player={type_player}
                border_color={border_color}
            />
            {showPlayer && (
                <div
                    className={`main-audio-${type_player}`}
                >
                    {renderCurrentTime}
                    <ProgressBar
                        volume={progress}
                        min={0}
                        max={audio?.duration}
                        handleVolume={values => handleProgressClick(values[0])}
                        color={main_color}

                    />
                    {renderDuration}
                                    </div>
            )}
            <Volume
                color={main_color}
                type_player={type_player}
                volume={volume}
                max={1}
                min={0}
                background_color={background_color}
                border_color={border_color}
                setVolume={(volume) => {
                    audio.volume = volume;
                    setVolume(volume);
                }}
            />

            {Element(type_player)}

        </div>
    );
};
