import React, {useState} from 'react';
import {useAppDispatch, useAppSelector, useAuth} from '../../../hooks';
import {clearAppAuth} from '../../../pages/auth/store/auth.slice';
import {clearStorage} from '../../../store/app.slice';

export const Avatar = () => {
    const dispatch = useAppDispatch();
    const {isAuth} = useAuth();
    const {account} = useAppSelector(state => state.auth);
    const [isDrop, setDrop] = useState<boolean>(false);

    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(clearStorage());
        dispatch(clearAppAuth());
    };

    return (
        <div
            className="header_item-login-block"
        >
            <p className="header_item-login">
                <span>{isAuth ? account.email : 'Авторизуйтесь'}</span>
                <i className="fas fa-chevron-down"/>
                <img src="https://apihost.ru/images/icon/user.svg" alt={'icon'} onClick={() => setDrop(!isDrop)}/>
            </p>
            {isDrop && isAuth
                ? (
                    <>
                    <div className="header_item-login-drodown">
                        <p className="header_item-login-drodown-username">apihost</p>
                        <span className="header_item-login-drodown-logout" onClick={handleLogout}>
                            <i className="fas fa-arrow-right"/>
                            Выйти из системы
                        </span>
                    </div>
                        <div className="header_item-login-drodown--overlay" onClick={() => setDrop(false)}/>
                    </>
                ) : null}
        </div>
    );
};
