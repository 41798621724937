import React, {useState} from 'react';
import {Direction, getTrackBackground, Range} from 'react-range';
import {VolumeMute, VolumeUp} from '../icons';

interface IVolumeProps {
    type_player: string;
    volume: number;
    setVolume: (volume: number) => void;
    color: string;
    background_color: string;
    border_color: string;
    min: number;
    max: number
}

export const Volume = ({
                           type_player,
                           volume,
                           setVolume,
                           color,
                           background_color,
                           border_color,
                           max,
                           min
                       }: IVolumeProps) => {
    const [isMuted, setMuted] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    const handleVolume = (volume) => {
        setMuted(volume[0] === 0);
        setVolume(volume[0]);
    };

    return (
        <div className={`main-audio-volume-${type_player} main-audio-volume`}>
            <span onClick={() => setShow(!show)}>
                {
                    isMuted
                        ? <VolumeMute color={color} type_player={type_player} border_color={border_color}/>
                        : <VolumeUp color={color} type_player={type_player} border_color={border_color}/>
                }
            </span>
            {show ? (
                <div className={'main-audio-wrapper-range'} style={{
                    backgroundColor: background_color
                }}>
                    <Range
                        onChange={handleVolume}
                        values={[volume]}
                        step={0.1}
                        max={max}
                        min={min}
                        direction={Direction.Up}

                        renderTrack={({props, children}) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '40px',
                                    width: '6px',
                                    backgroundColor: 'rgba(33,33,33,0.25)',
                                    position: 'relative',
                                    borderRadius: '5px',
                                    background: getTrackBackground({
                                        values: [volume],
                                        colors: [color, '#ccc'],
                                        min: 0,
                                        max: 1,
                                        direction: Direction.Up
                                    }),
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({props}) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '15px',
                                    width: '15px',
                                    borderRadius: '50%',
                                    backgroundColor: color,
                                    position: 'absolute'
                                }}
                            />
                        )}/>
                </div>
            ) : null}

        </div>
    );
};
