import React from 'react';

interface IIconProps {
    width?: number;
    height?:number;
    color: string;
}

export const CheckedIcon = ({width =19, height = 13, color ='#565656'}:IIconProps) => {
    return (
        <svg width="19.721" height="13.572" viewBox="0 0 19.721 13.572">
            <line stroke={color} strokeWidth={3} x1="1" y1="5" x2="8" y2="11"/>
            <line stroke={color} strokeWidth={3} x1="19" y1="2" x2="7" y2="11"/>
        </svg>
    );
};
